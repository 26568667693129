import { getLocale, getTimeZone } from "@/services/localisation";
import { DateTime } from "luxon"

function offsetMatchesCurrentTimeZone(dateTime) {
    let localDateTime = dateTime
        .setZone(getTimeZone());

    return dateTime.offset === localDateTime.offset;
}

function formatOffset(offset) {
    if (offset === 0) {
        return "UTC";
    }

    let hours = parseInt(Math.abs(offset / 60));
    let minutes = Math.abs(offset % 60);
    let prefix = offset > 0 ? "+" : "-";

    if (minutes === 0) {
        minutes = "";
    }
    else if (minutes < 10) {
        minutes = ":0" + minutes;
    }
    else {
        minutes = ":" + minutes;
    }

    return "UTC" + prefix + hours + minutes;
}

export function format(value) {
    if (value == null) {
        return "";
    }

    let dateTime = DateTime
        .fromISO(value, { setZone: true })
        .setLocale(getLocale());

    let result = dateTime
        .toLocaleString(DateTime.DATETIME_SHORT);

    // Only format the offset if it's different from the user's current time zone.
    if (offsetMatchesCurrentTimeZone(dateTime)) {
        return result;
    }

    // We need to format the
    return `${result} ${formatOffset(dateTime.offset)}`;
}
